import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IProjectRecordAssociatedUser } from '../models/interface';
import {
  IRecordDeleteResponse,
  IRecordDetailsResponse,
  IRecordCustomJurisdictions,
  IRecordJurisdictionInfo,
  IRecordPayload,
  IRequestCustomJurisdictionsData,
  IResponseCreateEditRecord,
  IAddSourceFormData,
  IResponseAddNewSource,
  IResponseSourceList,
  IRequestDeleteSource,
  IResponseDeleteSource,
  IAddSourceCitationFormData,
  ISourceCitationCreateResponse,
  IRequestMarkFinished,
  IRequestAnswer,
  IResponseAnswer,
  IUploadFile,
  IDeleteAttachedFile,
  IResponseRecordLastSaved,
  IRequestAssociateCitation,
  IResponseAssociateCitation,
  IDownloadAttachedFile,
  IResponseDownloadAttachedFile,
  IRequestAmendSource,
  IResponseAmendSource,
  IRequestRecordManageUsers,
  IAmendRecord,
  IRequestGetClonedRecordCount,
  IRequestCloneRecord,
  IResponseCloneRecord,
  IJurisdictionDetails,
  IRequestRestoreRecord,
  IResponseRestoreRecord,
  IRequestHideUnhideRecord,
  IResponseHideUnhideRecord,
  IRecordTimelineData,
  IRequestUpdateSourceTitle,
  IRequestUpdateSourceTimeline,
  IRequestUpdateSourceText,
  IResponseDetachSource,
  IJurisdictionPayload,
  IResponseAttachFilesInfo
} from "../models/interface/record.interface";
import { URLService } from './url.service';

export class RecordsService {
  public async createRecord(payload: Omit<IRecordPayload, 'record_id'>) {
    const { updatedURL, updatedData } = new URLService<Omit<IRecordPayload, 'record_id'>>(payload).formatURL(RoutesEnum.ORG_CREATE_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditRecord>>(updatedURL, { ...updatedData });
  }

  public async editRecord(payload: IRecordPayload) {
    const { updatedURL, updatedData } = new URLService<IRecordPayload>(payload).formatURL(RoutesEnum.ORG_EDIT_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditRecord>>(updatedURL, { ...updatedData });
  }

  public async getRecordDetailsData({
    project_slug,
    record_id,
  }: {
    project_slug: string;
    record_id: number;
  }) {
    const { updatedURL } = new URLService({ project_slug, record_id }).formatURL(RoutesEnum.ORG_GET_PROJECT_RECORD_DETAILS);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<IRecordDetailsResponse>>(updatedURL);
  }

  public async deleteProjectRecord({
    project_slug,
    record_id,
  }: {
    project_slug: string;
    record_id: number;
  }) {
    const { updatedURL } = new URLService({ project_slug, record_id }).formatURL(RoutesEnum.ORG_DELETE_PROJECT_RECORD);
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<IRecordDeleteResponse>>(updatedURL);
  }

  public async addNewSource(payload: IAddSourceFormData, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<IAddSourceFormData>(payload, project_slug).formatURL(RoutesEnum.ORG_ADD_RECORD_SOURCE);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddNewSource>>(updatedURL, updatedData);
  }

  public async updateSourceTitle(payload: IRequestUpdateSourceTitle, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<IRequestUpdateSourceTitle>(payload, project_slug).formatURL(RoutesEnum.ORG_UPDATE_RECORD_SOURCE_TILE);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddNewSource>>(updatedURL, updatedData);
  }

  public async updateSourceTimeline(payload: IRequestUpdateSourceTimeline, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<IRequestUpdateSourceTimeline>(payload, project_slug).formatURL(RoutesEnum.ORG_UPDATE_RECORD_SOURCE_TIMELINE);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddNewSource>>(updatedURL, updatedData);
  }

  public async updateSourceText(payload: IRequestUpdateSourceText, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<IRequestUpdateSourceText>(payload, project_slug).formatURL(RoutesEnum.ORG_UPDATE_RECORD_SOURCE_TEXT);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAddNewSource>>(updatedURL, updatedData);
  }

  public async addNewSourceCitation(payload: IAddSourceCitationFormData, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<IAddSourceCitationFormData>(payload, project_slug).formatURL(RoutesEnum.ORG_ADD_RECORD_SOURCE_CITATION);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<ISourceCitationCreateResponse>>(updatedURL, updatedData);
  }

  public async deleteSourceCitation(payload: { citation_id: number; law_id: number; record_id: number; }, project_slug: string) {
    const { updatedURL, updatedData } = new URLService<{ citation_id: number; law_id: number; record_id: number; }>(payload, project_slug).formatURL(RoutesEnum.ORG_DELETE_RECORD_SOURCE_CITATION);
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<{ citation_id: number; law_id: number; }>>(updatedURL, { data: { ...updatedData } });
  }

  public async addRemoveQuestionsFromCitation(project_slug: string, record_id: number, citation_id: number, question_ids: number[]) {
    const { updatedURL, updatedData } = new URLService<{ record_id: number, citation_id: number, question_ids: number[] }>({
      record_id,
      citation_id,
      question_ids
    }, project_slug).formatURL(RoutesEnum.ORG_RECORD_SOURCE_CITATION_QUESTIONS_LINK);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<{ linked_question_ids: number[] }>>(updatedURL, updatedData);
  }

  public async getSourceList(record_id: number, project_slug: string) {
    const { updatedURL } = new URLService({ project_slug, record_id }).formatURL(RoutesEnum.ORG_GET_RECORD_SOURCE_LIST);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseSourceList>>(updatedURL);
  }

  public async deleteRecordSource(params: IRequestDeleteSource) {
    const { updatedURL } = new URLService(params).formatURL(RoutesEnum.ORG_DELETE_RECORD_SOURCE);
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseDeleteSource>>(updatedURL, { data: { law_id: params.law_id } });
  }

  public async detachRecordSource(params: IRequestDeleteSource) {
    const { updatedURL } = new URLService(params).formatURL(RoutesEnum.ORG_DETACH_RECORD_SOURCE);
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseDetachSource>>(updatedURL, { data: { law_id: params.law_id } });
  }
  /**
   * Custom Jurisdiction api
   */
  public async getCustomJurisdictions(params: IRequestCustomJurisdictionsData) {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<IRecordCustomJurisdictions>
    >(RoutesEnum.ORG_GET_CUSTOM_JURISDICTIONS, { params });
  }

  public async getAmendRecordsInfo(payload: IJurisdictionPayload, project_slug: string) {
    const forceUpdateURL = RoutesEnum.ORG_CHECK_JURISDICTION_EXISTS.replace(
      "{project_slug}",
      project_slug.toString()
    );

    return (await new ApiService().privateClient()).post<IApiBaseResponse<IRecordJurisdictionInfo>>(forceUpdateURL, { ...payload });
  }

  public async getJurisdictionDetailsFromDB(
    payload: IJurisdictionPayload,
    project_slug: string
  ) {
    const forceUpdateURL =
      RoutesEnum.ORG_EXIST_JURISDICTION_INFO.replace(
        "{project_slug}",
        project_slug.toString()
      );

    return (await new ApiService().privateClient()).post<IApiBaseResponse<IJurisdictionDetails>>(forceUpdateURL, { ...payload });
  }

  public async markFinishedRecord(payload: IRequestMarkFinished) {
    const { updatedURL, updatedData } = new URLService<IRequestMarkFinished>(payload).formatURL(RoutesEnum.ORG_MARK_FINISHED_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<{ record_id: number }>>(updatedURL, updatedData);
  }

  public async answerRecordQuestions(payload: IRequestAnswer) {
    const { updatedURL, updatedData } = new URLService<IRequestAnswer>(payload).formatURL(RoutesEnum.ORG_ADD_EDIT_ANSWER);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAnswer>>(updatedURL, updatedData);
  }

  public async uploadAttachedFiles(payload: IUploadFile) {
    const { updatedURL, updatedData } = new URLService<IUploadFile>(payload).formatURL(RoutesEnum.ORG_UPLOAD_ATTACHED_FILE);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAttachFilesInfo>>(updatedURL, updatedData);
  }

  public async deleteAttachedFile(payload: IDeleteAttachedFile) {
    const { updatedURL, updatedData } = new URLService<IDeleteAttachedFile>(payload).formatURL(RoutesEnum.ORG_DELETE_ATTACHED_FILE);
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseAttachFilesInfo>>(updatedURL, { data: updatedData });
  }

  public async downloadAttachedFile(params: IDownloadAttachedFile) {
    const { updatedURL, updatedData } = new URLService<IDownloadAttachedFile>(params).formatURL(RoutesEnum.ORG_DOWNLOAD_ATTACHED_FILE);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseDownloadAttachedFile>>(updatedURL, { params: updatedData });
  }

  public async getRecordLastSaved(project_slug: string, record_id: number) {
    const { updatedURL } = new URLService({ record_id }, project_slug).formatURL(RoutesEnum.ORG_GET_RECORD_LAST_SAVED);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseRecordLastSaved>>(updatedURL);
  }

  public async associateCitationWithQuestion(project_slug: string, payload: IRequestAssociateCitation) {
    const { updatedURL, updatedData } = new URLService<IRequestAssociateCitation>(payload, project_slug).formatURL(RoutesEnum.ORG_ASSOCIATE_CITATION_WITH_QUESTION);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAssociateCitation>>(updatedURL, updatedData);
  }

  public async getAssociatedUsers(params: Omit<IRequestRecordManageUsers, 'users_associated'>) {
    const { updatedURL } = new URLService(params).formatURL(RoutesEnum.ORG_GET_RECORD_ASSOCIATED_USERS);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<{ users_associated: IProjectRecordAssociatedUser[] | null }>>(updatedURL);
  }

  public async manageRecordPermissions(payload: IRequestRecordManageUsers) {
    const { updatedURL, updatedData } = new URLService<IRequestRecordManageUsers>(payload).formatURL(RoutesEnum.ORG_MANAGE_RECORD_PERMISSIONS);
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(updatedURL, updatedData);
  }

  public async amendSource(project_slug: string, payload: IRequestAmendSource) {
    const { updatedURL, updatedData } = new URLService<IRequestAmendSource>(payload,project_slug).formatURL(RoutesEnum.ORG_RECORD_AMEND_SOURCE);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseAmendSource>>(updatedURL, updatedData);
  }
  public async amendRecord(params: IAmendRecord) {
    const { updatedURL, updatedData } = new URLService<IAmendRecord>(params).formatURL(RoutesEnum.ORG_RECORD_AMEND_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<{amended_law_ids:number[]}>>(updatedURL, updatedData);
  }
  public async getClonedRecordCount(params: IRequestGetClonedRecordCount) {
    const { updatedURL } = new URLService(params).formatURL(RoutesEnum.ORG_RECORD_GET_CLONED_RECORD_COUNT);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<{ cloned_record_count: number }>>(updatedURL,{ params: params });
  }
  public async cloneRecord(params: IRequestCloneRecord) {
    const { updatedURL, updatedData } = new URLService<IRequestCloneRecord>(params).formatURL(RoutesEnum.ORG_RECORD_ADD_CLONE_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCloneRecord>>(updatedURL, updatedData);
  }
  public async restoreRecord(params: IRequestRestoreRecord) {
    const { updatedURL } = new URLService({}, params.project_slug).formatURL(RoutesEnum.ORG_RECORD_RESTORE_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseRestoreRecord>>(updatedURL, params);
  }
  public async hideUnhideRecord(params: IRequestHideUnhideRecord) {
    const { updatedURL, updatedData } = new URLService<IRequestHideUnhideRecord>(params).formatURL(RoutesEnum.ORG_RECORD_HIDE_UNHIDE_RECORD);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseHideUnhideRecord>>(updatedURL, updatedData);
  }

  public async getRecordTimelineViewData(project_slug: string, record_id: number, series_title?: string) {
    const { updatedURL, updatedData } = new URLService({ record_id, series_title }, project_slug).formatURL(RoutesEnum.ORG_GET_RECORD_TIMELINE_DATA);
    return (await new ApiService().privateClient()).get<IApiBaseResponse<{records: IRecordTimelineData[]}>>(updatedURL, { params: updatedData });
  }

  public async triggerSuggestiveText(project_slug: string, record_id: number) {
    const { updatedURL } = new URLService({ record_id }, project_slug).formatURL(RoutesEnum.ORG_TRIGGER_SUGGESTIVE_TEXT);
    return (await new ApiService().privateClient()).post<IApiBaseResponse<{job_id: string}>>(updatedURL);
  }
}
